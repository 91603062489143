<template>
  <v-card class="pa-3">
    <v-card-title class="text-h5"> Delete Payment Allocation </v-card-title>
    <v-card-text
      >This will remove all links to customer sales orders and bank
      transactions.</v-card-text
    >
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red" dark elevation="0" @click="unreconcileCPADocument()">
        <v-icon left small>mdi-delete</v-icon>
        Delete
      </v-btn>
      <v-btn text @click="passCloseDialog" :loading="this.loading_delete">
        Cancel
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {
  unreconcileSalesOrders,
  unreconcileBankTransactions,
  deleteCPADocument,
  unreconcileCPADocument,
} from "../data/external_customer_payment_allocation";

export default {
  name: "CustomerPaymentAllocationRemoveRedoDialog",
  props: ["value", "remove_and_redo_dialog", "sales_orders", "cpa_doc"],
  data() {
    return {
      loading_delete: false,
    };
  },
  methods: {
    passCloseDialog() {
      this.$emit("changeRemoveDialog");
    },
    unreconcileCPADocument,
    unreconcileSalesOrders,
    unreconcileBankTransactions,
    deleteCPADocument,
    passKeyChange() {
      this.$emit("closeDialog");
    },
  },
};
</script>
